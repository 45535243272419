/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Typography, Box, Link, BoxProps } from '@material-ui/core';

const Privacy = (props: BoxProps): ReactElement => (
  <>
    <Box p={4} mt={4} {...props}>
      <Box m={2}>
        <Typography variant="h1">DATA PRIVACY</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Novozymes A/S is committed to protecting your personal information
          <br />
          The following describes the way we handle information gained about you from your visit to our website in
          accordance with applicable rules on protection of personal data, including the EU General Data Protection
          Regulation no. 679/1016 (“GDPR”).
          <br />
          This policy applies to the website, https://starch.novozymes.cloud (“the Website”).
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Your personal data</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">What information do we collect and why?</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The Website collects the following personal data about our online visitors.
          <br />
          1. name, e-mail address and other identification details (contact information) when voluntarily provided by an
          online visitor
          <br />
          2. user behavior tracking through the use of cookies
          <br />
          Your contact information will be used for the direct purpose requested by you such as e-mail notification or
          visitor feedback.
          <br />
          User behavior tracking is carried out to improve our website and the marketing of our products. See further
          description below under “Cookies” and in the separate <Link href="/cookies">“Cookie Policy”</Link>.
          <br />
          The legal basis for such processing of your personal data under GDPR is our legitimate interest in maintaining
          and improving our online content, cf. GDPR article 6, 1 (f) and/or your consent, if you have provided a such,
          cf. GDPR article 6, 1 (a).
          <br />
          Your personal information will be retained for as long as it is relevant to fulfil the purpose for which it
          has been registered. We have implemented technical and organizational measures to keep it safe.
          <br />
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Transfers of data</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Your personal information may be disclosed to affiliates in the Novozymes group to the extent relevant to
          fulfil your specific request. Please see the list of Novozymes’ affiliates{' '}
          <Link href="https://www.novozymes.com/en/about-us/management-ownership/subsidiaries">here</Link>.
          <br />
          Your personal information may also be disclosed to service providers, data processors or other business
          partners, who assist Novozymes and work under our instructions.
          <br />
          In some cases, we will be transferring personal data to countries outside the EU/EEA. Such transfers will only
          take place for the specific purposes mentioned above under the section “What information do we collect and
          why?”. We will ensure that appropriate safeguards are in place for such transfer. You may obtain a copy of the
          contract/agreement template by contacting us at{' '}
          <Link href="mailto:legal@novozymes.com">legal@novozymes.com</Link>.
          <br />
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Your rights</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          If we process personal data about you, you have certain data protection rights:
          <br />
          You have the right to request access to and rectification or erasure of your personal data.
          <br />
           You also have the right to object to the processing of your personal data and to have the processing of your
          personal data restricted.
          <br />
           In particular, you have an unconditional right to object to the processing of your personal data for direct
          marketing purposes.
          <br />
           If processing of your personal information is based on your consent, you have the right to withdraw your
          consent at any time. Your withdrawal will not affect the lawfulness of the processing carried out before you
          withdrew your consent. You may withdraw your consent by contacting us at legal@novozymes.com.
          <br />
           You may always lodge a complaint with a data protection supervisory authority, e.g. The Danish Data
          Protection Agency (&qout;Datatilsynet&qout;).
          <br />
           There may be conditions or limitations on these rights. You can take steps to exercise your rights by
          contacting us at legal@novozymes.com.
          <br />
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Cookies</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The Website automatically tracks how the content provided is used. This tracking allows us to gain a better
          understanding of potential areas for improvement so as to continually improve the relevance of and
          accessibility to the content we provide.
          <br />
          To this end the Website may at times deposit certain pieces of information called &qout;cookies&qout; on a
          visitor&apos;s computer. These cookies allow a more detailed level of tracking to fine-tune our usage reports.
          However, should you have cookies disabled on your machine, then you will continue to have exactly the same
          access to the Website&apos;s content as with cookies enabled, only generating slightly less accurate usage
          figures for our own internal reports.
          <br />
          This usage information will only be used within Novozymes, and our relevant partners as described above under
          the section “Transfers of data”, to increase the quality of content, service, and support for our visitors.
          Some of this information may be made public from time to time, but only in an aggregate, anonymized form such
          as e.g. how many people have visited the Website.
          <br />
          Find out more about cookies <Link href="/cookies">here</Link>.
          <br />
        </Typography>
      </Box>
    </Box>
  </>
);

export default Privacy;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { Auth } from '@aws-amplify/auth';
import { DropDown } from '@novozymes/components';
import PopupMenu from './PopupMenu';
import { getUserName } from 'utils/getUser';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4),
  },
  logoWrapper: {
    width: '82px',
  },
  logo: {
    width: '90px',
    cursor: 'pointer',
  },
  userMenu: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '300px',
  },
  userMenuIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const Header = (): ReactElement => {
  const classes = useStyles();
  const [user, setUser] = useState<string>('No user');
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const history = useHistory();

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleSignOut = useCallback(() => {
    Auth.signOut();
  }, []);

  useEffect(() => {
    getUserName().then((userName: any) => {
      setUser(userName);
    });
  }, []);

  const handleLogoClick = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleTermsClick = useCallback(() => {
    history.push('/terms');
  }, [history]);

  return (
    <Box className={classes.headerWrapper}>
      <Box className={classes.logoWrapper}>
        <img
          className={classes.logo}
          onClick={handleLogoClick}
          src="/novozymes_logo_simple_black.png"
          alt="Novozymes logo"
        />
      </Box>
      <Box>
        <Box className={classes.userMenu} onClick={handleMenuOpen}>
          <Typography variant="body1" ref={anchorRef}>
            {user}
          </Typography>
          <DropDown className={classes.userMenuIcon} />
        </Box>
        <PopupMenu
          open={menuOpen}
          anchorRef={anchorRef}
          onClose={handleMenuClose}
          placement={'bottom-end'}
          actions={[
            {
              id: 'action-sign-out',
              label: 'Sign out',
              onClick: handleSignOut,
            },
            {
              id: 'action-terms',
              label: 'Terms & Conditions',
              onClick: handleTermsClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default Header;

/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Typography, Box, Link, BoxProps } from '@material-ui/core';

const TermsConditions = (props: BoxProps): ReactElement => (
  <>
    <Box p={4} mt={4} {...props}>
      <Box m={2}>
        <Typography variant="h1">Terms & Conditions</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The websites, (https://starch.novozymes.cloud/, https://starch-liquefaction.novozymes.cloud/,
          https://starch-saccharification.novozymes.cloud/)) (“the Website”), is owned and managed by Novozymes A/S,
          Krogshoejvej 36, DK-2880 Bagsvaerd, Denmark (“Novozymes”). This notice contains the terms & conditions
          governing the use of the Website, including legal notice, data privacy and information about cookies
          (hereinafter referred to as the “Terms & Conditions”). Please read the Terms & Conditions carefully before
          using the Website. When you use the Website you expressly accept the Terms & Conditions.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">PURPOSE OF THE WEBSITE</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The purpose of the website is commercial and support, and as such, it is meant to support Novozymes’ business
          by the sales of enzymes products to starch refineries. This is done by calculating savings by applying
          Novozymes product. The calculations are partly based on input from the user and partly from Novozymes
          technical data. The indications provided by this calculator are indicative, and are only meant as guidance,
          which should not replace actual full-scale trials. Contact Novozymes for further advice involving enzymes,
          before conducting trials.
        </Typography>
      </Box>

      <Box m={2}>
        <Typography variant="h2">INPUT DATA</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          When you use this Website, you are requested to provide input regarding the following input data
          <ol>
            <li>
              1.Production capacity: In order for the calculator to provide the best guidance/recommendation on your
              benefits, you need to specify details on production volumes, production capacity and chemical cost.
            </li>
            <li>
              2. Cost and region: In order for the calculator to provide the most relevant guidance/recommendation, you
              need to specify what region your plant is located in to accommodate regional settings
            </li>
          </ol>
          The Input data, when entered, is treated with confidentiality and they are used as an input for the calculator
          to deliver the savings, as well as improve the functionality of the calculator like. Data is stored on
          Novozymes servers which is necessary for supporting the save and share functionality of the tool. The data
          stored will only be shown to the associated user. The data stored will not be shared with any third parties.
        </Typography>
        <Typography variant="body1">
          Further details on data privacy are given here: <Link href="/privacy">Privacy policy</Link> By providing the
          Input Data you authorize Novozymes and its affiliates to use the Input Data for these purposes. Novozymes
          undertakes to keep your Input Data confidential. We do not share your Input Data with third parties.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">LIMITATION OF LIABILITY</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          See <Link href="/legal">Legal notice</Link>
        </Typography>
      </Box>

      <Box m={2}>
        <Typography variant="h2">INTELLECTUAL PROPERTY RIGHTS</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          See <Link href="/legal">Legal notice</Link>
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">GOVERNING LAW AND VENUE</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          See <Link href="/legal">Legal notice</Link>
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">DATA PRIVACY</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          See <Link href="/privacy">Data privacy</Link>
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">COOKIES</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          See <Link href="/cookies">Cookies</Link>
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">CONTACT</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          If you have any questions or comments with respect to this Legal notice, the Privacy policy, or other
          Information on the website, please do not hesitate to{' '}
          <Link href="https://www.novozymes.com/en/about-us/contact-us" target="_blank">
            contact Novozymes
          </Link>
        </Typography>
      </Box>
    </Box>
  </>
);

export default TermsConditions;

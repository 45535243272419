import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  makeStyles,
  IconButton,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useCallback, ChangeEvent, ReactElement } from 'react';
import { colors, Button, OutlinedInputWithLabel, Close } from '@novozymes/components';
import API from 'API';
import * as EmailValidator from 'email-validator';
// import logger from 'utils/logger';
import { getUserEmail } from 'utils/getUser';

export interface ShareScenarioDialogProps {
  show: boolean;
  scenarioId?: string;
  onClose(): void;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: colors.black67,
  },
}));

const ShareScenarioDialog = ({ show, scenarioId, onClose }: ShareScenarioDialogProps): ReactElement => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClose = useCallback(() => {
    setEmail('');
    setError('');
    setSuccess(false);
    onClose();
  }, [onClose]);

  const handleShare = useCallback(async () => {
    setError('');
    if (!EmailValidator.validate(email)) {
      setError('Please enter a valid email');
      return;
    }

    const userEmail = await getUserEmail();

    if (userEmail === email) {
      setError('You cannot share the scenario with yourself. Please enter a different email.');
      return;
    }

    setLoading(true);

    const body = {
      shareWithEmail: email,
    };

    try {
      const response = await API.Scenarios.share({ id: scenarioId, body });
      const responseData = response.data<{ statusCode: string; body: any; headers: any }>();

      if (responseData.statusCode === '201') {
        setError('');
        setSuccess(true);
      } else {
        setError('Please make sure the shared user has a Salesforce account');
      }
    } catch (e) {
      // logger.error('Failed to share scenario', { error: e });
      setError('Something went wrong please try again');
    } finally {
      setLoading(false);
    }
    setLoading(false);
  }, [email]);

  return (
    <>
      {!!scenarioId ? (
        <Dialog onClose={handleClose} open={show}>
          <DialogTitle disableTypography={false}>
            <Box mt={2} mx={3}>
              <Typography variant="body1">Share this Scenario</Typography>
            </Box>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="small">
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box mx={2}>
              {success ? (
                <>
                  <Typography
                    id="successful-share-info"
                    variant="body1"
                  >{`Scenario successfully shared with ${email}`}</Typography>
                </>
              ) : (
                <>
                  <Box mb={2}>
                    <Typography variant="body1">Invite another user to view and edit a copy of the scenario</Typography>
                  </Box>
                  <OutlinedInputWithLabel
                    label={'Share calculation with'}
                    value={email}
                    placeholder="email"
                    handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      const { value: newValue } = event.target;
                      setEmail(newValue);
                    }}
                    error={!!error}
                    errorMessage={error}
                  />
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box mb={2} mt={4} mr={2}>
              {success ? (
                <>
                  <Button id="btn-share-dialog-success-close" type="primary" onClick={handleClose}>
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Button id="btn-share-dialog-cancel" type="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  {loading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <Button id="btn-share-dialog-share" type="primary" onClick={handleShare}>
                      Share
                    </Button>
                  )}
                </>
              )}
            </Box>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default ShareScenarioDialog;

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  Fade,
} from '@material-ui/core';
import { Columns, ComparatorFn, DataGrid, RowsProp, ValueFormatterParams } from '@material-ui/data-grid';
import { Button, More, ArrowDown, ArrowUp } from '@novozymes/components';
import API from 'API';
import TermsPopup from 'components/TermsPopup';
import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import formatDate from 'utils/formatDate';
import navigateToApp, { AppType } from 'utils/navigateToApp';
import { isShareEnabled } from 'utils/processUtils';
import useWindowDimensions from 'utils/useWindowDimnensions';
import PopupMenu, { Action } from '../components/PopupMenu';
import ShareScenarioDialog from '../components/ShareScenarioDialog';
import UpdateNameDialog from '../components/UpdateNameDialog';

const useStyle = makeStyles((theme: Theme) => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  intro: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  largeTitle: {
    fontSize: '56px',
    lineHeight: '66px',
    fontWeight: 'bold',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  listWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  listContent: {
    minHeight: '400px',
    padding: theme.spacing(2, 8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    borderRadius: 0,
    border: 0,
    maxWidth: '960px',
    // To remove padding on the edit column
    '& .MuiDataGrid-colCell:last-of-type, .MuiDataGrid-cell:last-of-type': {
      padding: '0',
    },
  },
  buttonWrapper: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  cell: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  header: {
    fontSize: '14px',
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: '700',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },
  labIcon: {
    position: 'absolute',
    right: '0',
    top: 'calc(50vh - 225px)',
  },
  deleteDialog: {
    maxWidth: '450px',
    padding: theme.spacing(2),
    color: theme.palette.common.black,
  },
}));

const scenarioTypeDisplayName = (scenarioType) => {
  switch (scenarioType) {
    case 'liq':
      return 'Liq.';
    case 'sac':
      return 'Sacch.';
  }

  return '';
};

const defaultStringComparer: ComparatorFn = (v1, v2, cell1, cell2) => {
  return v1 === v2
    ? (cell1.data.id as string).localeCompare(cell2.data.id as string)
    : (v1 as string).localeCompare(v2 as string);
};

const defaultNumberComparer: ComparatorFn = (v1, v2, cell1, cell2) => {
  return v1 === v2 ? (cell1.data.id as string).localeCompare(cell2.data.id as string) : (v1 as number) - (v2 as number);
};

interface ScenarioFromBackend {
  ScenarioId: string;
  UserId: string;
  CreatorName?: string;
  ScenarioRegion?: string;
  Title?: string;
  UpdatedAt: number;
  ScenarioTypes: string[];
  EnzymeComparison?: Record<string, { refEnzymeName: string; newEnzymeName: string }>;
}

interface ScenarioGridRow {
  id: string;
  scenarioId?: string;
  title?: string;
  scenario?: string;
  type?: string;
  creator?: string;
  lastEdit?: number;
  editButton?: ReactNode;
}

type SelectedScenario = { id: string; title: string };

const EditMenu = ({
  type,
  scenario,
  openShare,
  openDelete,
  openRename,
}: {
  type: string;
  scenario: SelectedScenario;
  openShare: (scenario: SelectedScenario) => void;
  openDelete: (scenario: SelectedScenario) => void;
  openRename: (scenario: SelectedScenario) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onClose = () => {
    setMenuOpen(false);
  };

  const actions: Action[] = [
    {
      id: 'action-edit-scenario',
      label: 'Edit scenario',
      onClick: () => {
        navigateToApp(type as AppType, scenario.id)();
      },
    },
    {
      id: 'action-share-scenario',
      label: 'Share scenario',
      disabled: !isShareEnabled(),
      onClick: () => {
        openShare(scenario);
      },
    },
    {
      id: 'action-delete-scenario',
      label: 'Delete scenario',
      onClick: () => {
        openDelete(scenario);
      },
    },
    {
      id: 'action-rename-scenario',
      label: 'Rename scenario',
      onClick: () => {
        openRename(scenario);
      },
    },
  ];

  return (
    <div ref={ref}>
      <IconButton
        onClick={() => {
          setMenuOpen(true);
        }}
        size="small"
      >
        <More />
      </IconButton>
      <PopupMenu open={menuOpen} anchorRef={ref} onClose={onClose} placement="bottom-end" actions={actions} />
    </div>
  );
};

const Home = (): ReactElement => {
  const classes = useStyle();

  const [scenarios, setScenarios] = useState<ScenarioFromBackend[]>([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const { width: windowWidth } = useWindowDimensions();
  const [selectedScenario, setSelectedScenario] = useState<SelectedScenario | undefined>();

  const getScenarioData = () => {
    API.Scenarios.get()
      .then((result) => {
        if (result.responseStatus === 200) {
          const data: { body: ScenarioFromBackend[] | string; statusCode: string } = result.data();
          if (data.statusCode === '200' && typeof data.body !== 'string') {
            setScenarios(data.body);
          }
        }
      })
      .catch((error) => {
        console.error('Failed to load scenarios');
      });
  };

  useEffect(() => {
    getScenarioData();
  }, []);

  const columns: Columns = useMemo(() => {
    const gridWidth = gridRef.current?.offsetWidth || 960;
    const gridColumnWidth = windowWidth < gridWidth ? windowWidth / 12 : gridWidth / 12;

    const EditCell = (params: ValueFormatterParams): ReactElement => {
      return (
        <EditMenu
          type={params.data.type}
          scenario={{ id: params.data.scenarioId, title: params.data.title }}
          openShare={handleShareClick}
          openDelete={handleDeleteClick}
          openRename={handleRenameClick}
        />
      );
    };

    return [
      {
        field: 'title',
        headerName: 'Title',
        width: gridColumnWidth * 3,
        cellClassName: classes.cell,
        headerClassName: classes.header,
      },
      {
        field: 'scenario',
        headerName: 'Scenario',
        width: gridColumnWidth * 3.5,
        cellClassName: classes.cell,
        headerClassName: classes.header,
      },
      {
        field: 'type',
        headerName: 'Liq. / Sacch.',
        width: gridColumnWidth * 1.5,
        cellClassName: classes.cell,
        headerClassName: classes.header,
        sortComparator: defaultStringComparer,
      },
      {
        field: 'creator',
        headerName: 'Creator',
        width: gridColumnWidth * 2,
        cellClassName: classes.cell,
        headerClassName: classes.header,
        sortComparator: defaultStringComparer,
      },
      {
        field: 'lastEdit',
        headerName: 'Last edit',
        width: gridColumnWidth * 1.5,
        cellClassName: classes.cell,
        headerClassName: classes.header,
        valueFormatter: (params: ValueFormatterParams) => formatDate(params.value as number),
        sortComparator: defaultNumberComparer,
      },
      {
        field: 'editButton',
        headerName: 'Edit',
        width: gridColumnWidth * 0.5,
        cellClassName: classes.cell,
        headerClassName: classes.header,
        renderCell: EditCell,
      },
    ];
  }, [windowWidth, gridRef.current, classes.cell, setSelectedScenario]);

  const rows: RowsProp = scenarios.reduce<ScenarioGridRow[]>((acc, scenarioFromBackend: ScenarioFromBackend) => {
    scenarioFromBackend.ScenarioTypes.forEach((scenarioType) => {
      const { EnzymeComparison, ScenarioId, Title, CreatorName, UpdatedAt } = scenarioFromBackend;

      const scenarioEnzymes = EnzymeComparison && EnzymeComparison[scenarioType];
      const scenarioDescription = `${scenarioEnzymes?.refEnzymeName || '–'} vs ${
        scenarioEnzymes?.newEnzymeName || '–'
      }`;
      acc.push({
        id: `${ScenarioId}:${scenarioType}`,
        scenarioId: ScenarioId,
        title: Title,
        scenario: scenarioDescription,
        type: scenarioTypeDisplayName(scenarioType),
        creator: CreatorName,
        lastEdit: UpdatedAt,
        editButton: '',
      });
    });
    return acc;
  }, []);

  const handleMenuOpen = useCallback(() => {
    setMenuOpen(true);
  }, []);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const handleNewClick = useCallback((type: AppType) => navigateToApp(type), []);

  const handleRowClick = useCallback(navigateToApp, []);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDeleteClick = useCallback((clickedScenario) => {
    setSelectedScenario(clickedScenario);
    setDialogOpen(true);
  }, []);

  const handleShareClick = useCallback((clickedScenario) => {
    setSelectedScenario(clickedScenario);
    setShareDialogOpen(true);
  }, []);

  const handleRenameClick = useCallback((clickedScenario) => {
    setSelectedScenario(clickedScenario);
    setNameDialogOpen(true);
  }, []);

  const handleDeleteSubmit = useCallback(() => {
    if (selectedScenario?.id) {
      API.Scenarios.delete({ id: selectedScenario.id })
        .then(() => {
          setDialogOpen(false);
          setSelectedScenario(undefined);
          getScenarioData();
        })
        .catch((err) => {
          console.error('Error deleting', err);
        });
    }
  }, [selectedScenario]);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const [nameDialogOpen, setNameDialogOpen] = useState(false);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  return (
    <>
      <TermsPopup />
      <UpdateNameDialog
        show={nameDialogOpen}
        onClose={() => {
          setNameDialogOpen(false);
          setSelectedScenario(undefined);
          getScenarioData();
        }}
        scenarioId={selectedScenario?.id}
        currentTitle={selectedScenario?.title}
      />
      <ShareScenarioDialog
        show={shareDialogOpen}
        onClose={() => {
          setShareDialogOpen(false);
          setSelectedScenario(undefined);
        }}
        scenarioId={selectedScenario?.id}
      />
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <Box className={classes.deleteDialog}>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body1">Are you sure you’d like to delete this scenario?</Typography>
              <Typography variant="body1">Once deleted, you can’t recover it.</Typography>
            </DialogContentText>
            <Box mb={5} />
          </DialogContent>
          <DialogActions>
            <Button type="secondary" small onClick={handleCloseDialog}>
              Back
            </Button>
            <Button type="primary" small onClick={handleDeleteSubmit}>
              Delete
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Box mb={2} className={classes.intro}>
        <Typography className={classes.largeTitle}>Refinery Optimizer </Typography>
        <Box mt={3}></Box>
        <Typography variant="subtitle2">
          Predict how to save time, energy or chemical costs by <br /> introducing the right enzyme.
        </Typography>
        <div ref={anchorRef} className={classes.buttonWrapper}>
          <PopupMenu
            open={menuOpen}
            anchorRef={anchorRef}
            onClose={handleMenuClose}
            placement="bottom"
            actions={[
              {
                id: 'btn-new-liq-scenario',
                label: 'Liquefaction',
                onClick: handleNewClick('Liq.'),
              },
              {
                id: 'btn-new-sacch-scenario',
                label: 'Saccharification',
                onClick: handleNewClick('Sacch.'),
              },
            ]}
          />

          <Button id="btn-new-scenario" type="primary" small onClick={handleMenuOpen}>
            New scenario
          </Button>
        </div>
      </Box>

      <Fade in={rows.length > 0}>
        <Box className={classes.listWrapper}>
          <Box id="list-scenario" className={classes.listContent}>
            <DataGrid
              ref={gridRef}
              className={classes.list}
              rows={rows}
              columns={columns}
              hideFooter
              autoHeight
              columnBuffer={0}
              onCellClick={(cellParams) => {
                if (cellParams.field !== 'editButton') {
                  handleRowClick(cellParams.data.type, cellParams.data.scenarioId)();
                }
              }}
              icons={{
                columnSortedAscending: ArrowUp,
                columnSortedDescending: ArrowDown,
              }}
              sortModel={[
                {
                  field: 'lastEdit',
                  sort: 'desc',
                },
              ]}
            />
          </Box>
        </Box>
      </Fade>

      {!rows.length && (
        <Box className={classes.labIcon}>
          <img src="/lab_icon.svg" alt="lab icon" />
        </Box>
      )}
    </>
  );
};

export default Home;

const TERMS_ACCEPTED_KEY = 'starch-terms-accepted';
const ACCEPTED_VALUE = 'accepted';

export const termsAccepted = (): boolean => {
  return localStorage.getItem(TERMS_ACCEPTED_KEY) === ACCEPTED_VALUE;
};

export const setTermsAccepted = (): boolean => {
  try {
    localStorage.setItem(TERMS_ACCEPTED_KEY, ACCEPTED_VALUE);
  } catch (error) {
    return false;
  }
  return true;
};

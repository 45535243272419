import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  makeStyles,
  IconButton,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import React, { useState, useCallback, ChangeEvent, ReactElement, useEffect } from 'react';

import { colors, Button, OutlinedInputWithLabel, Close } from '@novozymes/components';
import API from 'API';

export interface UpdateNameDialogProps {
  show: boolean;
  scenarioId?: string;
  currentTitle?: string;
  onClose(): void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: '400px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: colors.black67,
  },
}));

const UpdateNameDialog = ({ show, scenarioId, currentTitle, onClose }: UpdateNameDialogProps): ReactElement => {
  const [newTitle, setNewTitle] = useState<string | undefined>(currentTitle);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setNewTitle(currentTitle);
  }, [currentTitle]);

  const handleClose = useCallback(() => {
    setError('');
    setSuccess(false);
    onClose();
  }, [onClose]);

  const handleSave = useCallback(async () => {
    setError('');
    if (typeof newTitle !== 'string' || newTitle.length === 0) {
      setError('Please enter a name');
      return;
    }

    setLoading(true);

    const body = {
      title: newTitle,
    };

    try {
      const response = await API.Scenarios.update({ id: scenarioId, body });
      const responseData = response.data<{ statusCode: string; body: any; headers: any }>();

      if (responseData.statusCode === '200') {
        setError('');
        setSuccess(true);
      } else {
        setError('Please make sure the shared user has a Salesforce account');
      }
    } catch (e) {
      // logger.error('Failed to share scenario', { error: e });
      setError('Something went wrong please try again');
    } finally {
      setLoading(false);
    }
    setLoading(false);
  }, [newTitle, scenarioId]);

  return (
    <>
      {!!scenarioId ? (
        <Dialog onClose={handleClose} open={show}>
          <DialogTitle disableTypography={false}>
            <Box mt={2} mx={3}>
              <Typography variant="body1">Share this Scenario</Typography>
            </Box>

            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose} size="small">
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <Box mx={2}>
              {success ? (
                <>
                  <Typography variant="body1">Scenario successfully updated</Typography>
                </>
              ) : (
                <>
                  <OutlinedInputWithLabel
                    label={'New scenario title'}
                    value={newTitle}
                    placeholder="Title"
                    handleChange={(event: ChangeEvent<HTMLInputElement>): void => {
                      const { value: newValue } = event.target;
                      setNewTitle(newValue);
                    }}
                    error={!!error}
                    errorMessage={error}
                  />
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box mb={2} mt={4} mr={2}>
              {success ? (
                <>
                  <Button type="primary" onClick={handleClose}>
                    Close
                  </Button>
                </>
              ) : (
                <>
                  <Button type="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  {loading ? (
                    <CircularProgress color="primary" />
                  ) : (
                    <Button type="primary" onClick={handleSave}>
                      Save
                    </Button>
                  )}
                </>
              )}
            </Box>
          </DialogActions>
        </Dialog>
      ) : null}
      ;
    </>
  );
};

export default UpdateNameDialog;

import { createMuiTheme } from '@material-ui/core/styles';
import { NovozymesTheme } from '@novozymes/components';

const starchTheme = createMuiTheme({
  ...NovozymesTheme,
  typography: {
    ...NovozymesTheme.typography,
    body1: {
      ...NovozymesTheme.typography.body1,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 'inherit',
    },
    h1: {
      ...NovozymesTheme.typography.h1,
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '28px',
      marginBottom: NovozymesTheme.spacing(1),
      letterSpacing: 'inherit',
    },
    subtitle1: {
      ...NovozymesTheme.typography.subtitle1,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 'inherit',
    },
    subtitle2: {
      ...NovozymesTheme.typography.subtitle2,
      fontSize: '20px',
      lineHeight: '28px',
      letterSpacing: 'inherit',
    },
    h2: {
      ...NovozymesTheme.typography.h2,
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '28px',
      marginBottom: NovozymesTheme.spacing(2),
      letterSpacing: 'inherit',
    },
    h4: {
      ...NovozymesTheme.typography.h4,
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '22px',
      marginBottom: NovozymesTheme.spacing(2),
      letterSpacing: 'inherit',
    },
    h5: {
      ...NovozymesTheme.typography.h5,
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '20px',
      marginBottom: NovozymesTheme.spacing(2),
      letterSpacing: 'inherit',
    },
  },
  palette: {
    ...NovozymesTheme.palette,
    background: {
      default: '#ffffff',
    },
    action: {
      ...NovozymesTheme.palette.action,
      disabled: '#ffffff',
      disabledBackground: '#848c84',
    },
    primary: {
      ...NovozymesTheme.palette.primary,
      main: '#C5DA00',
      dark: '#545F54',
      light: '#838C83',
    },
    secondary: {
      ...NovozymesTheme.palette.secondary,
      main: '#0F3E49',
      dark: '#243C47',
      light: '#CED9DC',
    },
    grey: {
      ...NovozymesTheme.palette.grey,
      300: '#E6E6E6',
    },
  },
  shape: {
    ...NovozymesTheme.shape,
    borderRadius: 9,
  },
});

export default starchTheme;

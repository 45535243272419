import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Amplify } from '@aws-amplify/core';
import { Box, makeStyles } from '@material-ui/core';
import { colors } from '@novozymes/components';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import amplifyConfig from 'amplifyConfig';

import Home from 'pages/Home';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { AuthEvent, validateState } from '@novozymes-digital/auth';
import { initializeCookieConsent, initializeGTM } from '@novozymes-digital/utils';
import Redirecter from 'Redirecter';
import TagManager from 'react-gtm-module';
import Body from 'components/Body';
import TermsConditions from 'pages/TermsConditions';
import Cookies from 'pages/Cookies';
import Privacy from 'pages/Privacy';
import Legal from 'pages/Legal';
import { getUserType } from 'utils/getUser';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100vh',
    backgroundColor: colors.bg2,
    display: 'flex',
    flexDirection: 'column',
  },
}));

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'authentication-wrapper': any;
    }
  }
}

function App(): ReactElement {
  const [authLoaded, setAuthLoaded] = useState(false);
  const [authRedirect, setAuthRedirect] = useState<string | null | undefined>();
  const [userType, setUserType] = useState('not-logged-in');

  useEffect(() => {
    // Load cookie consent
    initializeCookieConsent();

    initializeGTM('GTM-KTWCZXC');
  }, []);

  const onAuthEvent = useCallback(async (event) => {
    const authEvent = event as AuthEvent;
    if (authEvent.detail.newAuthState === 'signedIn') {
      setAuthLoaded(true);
      const userType = await getUserType();
      setUserType(userType);
    }
  }, []);

  useEffect(() => {
    const redirect = validateState();
    setAuthRedirect(redirect);
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        user_type: userType,
      },
    });
  }, [userType]);

  // eslint-disable-next-line no-undef
  window.addEventListener('AuthStateChange', onAuthEvent);

  Amplify.configure(amplifyConfig);

  const classes = useStyles();

  return (
    <authentication-wrapper id="authentication" amplifyConfig={JSON.stringify(amplifyConfig)}>
      {authLoaded && authRedirect ? (
        <BrowserRouter>
          <Redirecter redirectTo={authRedirect}>
            <Box className={classes.wrapper}>
              <Switch>
                <Route path="/terms">
                  <Header />
                  <Body>
                    <TermsConditions />
                  </Body>
                  <Footer />
                </Route>
                <Route path="/cookies">
                  <Header />
                  <Body>
                    <Cookies />
                  </Body>
                  <Footer />
                </Route>
                <Route path="/privacy">
                  <Header />
                  <Body>
                    <Privacy />
                  </Body>
                  <Footer />
                </Route>
                <Route path="/legal">
                  <Header />
                  <Body>
                    <Legal />
                  </Body>
                  <Footer />
                </Route>
                <Route path="/">
                  <Header />
                  <Body>
                    <Home />
                  </Body>
                  <Footer />
                </Route>
              </Switch>
            </Box>
          </Redirecter>
        </BrowserRouter>
      ) : null}
    </authentication-wrapper>
  );
}

export default App;

/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Typography, Box, Link, BoxProps } from '@material-ui/core';

const Legal = (props: BoxProps): ReactElement => (
  <>
    <Box p={4} mt={4} {...props}>
      <Box m={2}>
        <Typography variant="h1">LEGAL NOTICE</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Legal notice Please read this legal notice and the privacy policy carefully before using the website These
          websites, https://starch.novozymes.cloud/, https://starch-liquefaction.novozymes.cloud/,
          https://starch-saccharification.novozymes.cloud/ (“the Website”), are owned and managed by Novozymes A/S
          (“Novozymes”). By using the Websites, you expressly accept that your use will be subject to the terms and
          conditions described in this Legal notice and in the <Link href="/privacy">Privacy policy</Link>. Further, you
          acknowledge that Novozymes may at any time change these terms and conditions. You should review the legal
          notice and the privacy policy each time you visit the website.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Disclaimer</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The documents and information on the Website (“Information”) are provided “as is” without warranties of any
          kind, whether express or implied, including, but not limited to, the implied warranties of merchantability,
          fitness for a particular purpose, and noninfringement. The Information on this site may include technical
          inaccuracies and errors. Novozymes does not guarantee the correctness, accuracy, reliability or completeness
          of the Information, and does not assume any liability under tort or contract law or any other legal theory for
          losses or failures of any kind related to the use of the Information. Novozymes is not responsible for the
          contents of any off-site pages or any other sites linked to or from this site. The Information on the Website
          is published for general information purposes only and does not constitute specific advice or recommendations.
          Novozymes and/or its affiliates shall in no event be held liable for damages (whether direct or indirect),
          injury, or losses of any kind resulting from use of, access to, or inability to access the Information on the
          Website.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Intellectual property rights</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          All Information on this site, including, but not limited to, any name, logo, text, software, product
          descriptions, formulas, photographs, video graphics or other material, is owned or licensed by Novozymes
          whether or not protected by copyright, trademarks, service marks, patents or other proprietary rights and
          laws. Nothing contained herein shall be construed as conferring any license or right under any patent or
          trademark of Novozymes or any third party. Any unauthorized use of these names, materials, etc., may be
          subject to penalties or damages, including, but not limited to, those related to violations of trademarks,
          copyrights and publicity rights.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Governing law and venue</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          This Legal notice, the <Link href="/privacy">Privacy policy</Link>, and other Information on the Website and
          any use thereof are governed exclusively by Danish law without regard to rules on choice of law. Any disputes
          arising out of or in connection with the Website shall be referred to the jurisdiction of the Danish ordinary
          courts.
        </Typography>
      </Box>
    </Box>
  </>
);

export default Legal;

import { Box, makeStyles } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';

interface BodyProps {
  children?: ReactNode;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Body = ({ children }: BodyProps): ReactElement => {
  const classes = useStyles();
  return <Box className={classes.wrapper}>{children}</Box>;
};

export default Body;

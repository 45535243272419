import { ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import React, { ReactElement } from 'react';

export interface Action {
  id: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface PopupMenuProps {
  open: boolean;
  anchorRef: any;
  onClose: () => void;
  placement:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  actions: Action[];
}
const useMenuStyles = makeStyles(() => ({
  popper: {
    zIndex: 1000,
  },
}));

const PopupMenu = (props: PopupMenuProps): ReactElement => {
  const { open, anchorRef, onClose, actions, placement } = props;
  const classes = useMenuStyles();

  return (
    <Popper
      open={open}
      className={classes.popper}
      anchorEl={anchorRef.current}
      role={undefined}
      placement={placement}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }): ReactElement => (
        <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow">
                {actions.map((action) => (
                  <MenuItem id={action.id} key={action.label} disabled={action.disabled} onClick={action.onClick}>
                    {action.label}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default PopupMenu;

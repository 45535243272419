const monthAsString = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const formatDate = (inputTimeStamp: number): string => {
  const timeStamp = Number(inputTimeStamp);
  if (!timeStamp) {
    return '';
  }
  const date = new Date(timeStamp);
  if (isNaN(date.getTime())) {
    return '';
  }
  const monthNumber = date.getMonth();
  const month = monthAsString[monthNumber] || '';
  return `${date.getDate()} ${month} ${date.getFullYear()}`;
};

export default formatDate;

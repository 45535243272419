import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  makeStyles,
  IconButton,
  DialogActions,
  CircularProgress,
  Link,
} from '@material-ui/core';
import React, { useState, useCallback, ChangeEvent, ReactElement, useEffect } from 'react';

import { colors, Button, OutlinedInputWithLabel, Close } from '@novozymes/components';
import API from 'API';
import { setTermsAccepted, termsAccepted } from 'utils/termsUtils';

export interface TermsPopupProps {
  onClose?(): void;
}

const useStyles = makeStyles((theme) => ({
  content: {
    minWidth: '400px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: colors.black67,
  },
}));

const TermsPopup = ({ onClose }: TermsPopupProps): ReactElement => {
  const [show, setShow] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    setShow(!termsAccepted());
  }, []);

  const handleContinue = useCallback(() => {
    setTermsAccepted();
    setShow(false);
    if (onClose) onClose();
  }, [onClose]);

  return (
    <>
      <Dialog disableBackdropClick disableEscapeKeyDown open={show}>
        <DialogTitle disableTypography={false}>
          <Box mt={2} mx={3}>
            <Typography variant="body1">Terms & Conditions</Typography>
          </Box>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Box mx={2}>
            <Typography variant="body1">
              By continueing to the app you accept the terms and conditions for the Starch Refinery Optimizer. You can
              view the term <Link href="/terms">here</Link>
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box mb={2} mt={4} mr={2}>
            <Button type="primary" onClick={handleContinue}>
              Continue
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      ;
    </>
  );
};

export default TermsPopup;

export type AppType = 'Liq.' | 'Sacch.';

const getUrlForApp = (appType: AppType): string | undefined => {
  switch (appType) {
    case 'Liq.':
      return process.env.REACT_APP_LIQ_URL;
    case 'Sacch.':
      return process.env.REACT_APP_SAC_URL;
    default:
      break;
  }
};

export default (appType: AppType, id?: string) => (): void => {
  const appUrl = getUrlForApp(appType);
  const fullURL = appUrl && `${appUrl}${id || ''}`;
  if (fullURL) {
    //eslint-disable-next-line no-restricted-globals
    window.location.assign(fullURL);
  }
};

/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Typography, Box, Link, TableHead, TableCell, Table, TableRow, TableBody, BoxProps } from '@material-ui/core';

const Cookies = (props: BoxProps): ReactElement => (
  <>
    <Box p={4} mt={4} {...props}>
      <Box m={2}>
        <Typography variant="h1">Cookie policy</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Novozymes’ website is owned and controlled by:
          <br />
          Novozymes A/S
          <br />
          Krogshoejvej 36
          <br />
          DK-2880 Bagsvaerd
          <br />
          Denmark
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          This Cookie Policy explains how we use cookies on our website. The website, https://starch.novozymes.cloud
          (“the Website”), uses cookies to improve the functionality of the site by collecting information about website
          usage. Some cookies are necessary for the site to function properly, and for us to gain a better understanding
          of potential areas of improvement so as to continually improve the relevance of and accessibility to the
          content we and you provide on the platform. As such, cookies provide us with more detailed tracking to
          finetune our web site usage reports. However, this information will only be used internally within the
          Novozymes group and will not be shared with any external stakeholders or be public at any time or transferred
          to third parties. Some cookies are set by third parties for website analytics, tracking and online-advertising
          purposes.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Cookie policy</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Novozymes makes an effort to offer a transparent website with the content that is most relevant to you. In
          order to do this, we use technologies that can collect, process and sort information regarding how you and
          other visitors use the Website.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Processing personal data by the use of cookies</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Novozymes’ use of cookies may include processing of your personal data, and we therefore recommend that you
          also read our <Link href="/privacy">privacy policy</Link>. When you visit the Website the first time, you are
          requested to accept our use of cookies by clicking “Accept” on our cookie consent banner. If you accept all
          cookies, then Novozymes will use cookies as described below, unless you have opted out of cookies in your
          browser. If you accept only the necessary cookies, then only those cookies will be used.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">What are cookies?</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Cookies are small text files, which the Website places on your computer&apos;s hard drive, smartphone or other
          electronic device. Cookies contain information, which the Website can use, among other things, to make the
          communication between you and your web-browser more efficient. Cookies can be used by Novozymes and by
          third-parties.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Why does Novozymes use cookies?</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Novozymes uses different kinds of cookies to provide you with an optimal user-experience and service, when you
          use the Website. We also use cookies to collect a basis for optimizing traffic on the Website and between
          other websites, to deliver content tailored to your needs and for marketing purposes. See below for more
          information about the purposes of our use of cookies.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Third-Party Cookies</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Not only Novozymes that can place cookies on your computer&apos;s hard drive, smartphone or other electronic
          device, when you visit the Websites. Third parties may also place cookies on your device, read more under
          &quot;Which cookies does Novozymes use and for which purposes?&quot;.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Other third-party cookies</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          It is also possible to use other third-party services on the Website, and these services may also use cookies.
          You can choose to block third party cookies in your browser - as mentioned below, or you may have chosen to do
          so when you visited the Website the first time. There may be functions on the Website that you cannot use, if
          you choose to block third party cookies in your browser. You will receive an error message that states how you
          are able to change your settings for third party cookies, thus enabling you to complete the desired actions.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Temporary and Permanent cookies</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          There are basically two types of cookies, &quot;temporary&quot; cookies and &quot;permanent&quot; cookies. The
          temporary cookies are linked to the current visit to the Website and are deleted automatically, when you close
          your web browser. In contrast, permanent cookies will be stored on your equipment. Permanent cookies delete
          themselves after a certain period but will be renewed every time you visit the Websites.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">How long do cookies survive?</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Lifetime of a cookie varies, and you can see the full list of expiry terms for all cookies in the table below.
          When they expire, they are deleted from your PC. The time when they are scheduled to expire is calculated from
          the last date you visited the homepage.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">How to avoid and delete cookies?</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          If you do not want Novozymes to place or read cookies on your equipment, you have the possibility of opting
          out of cookies in your browser, where you can choose which cookies that should be allowed, blocked or deleted.
          As user, you need to be aware that the Websites might not function optimally if you delete or block cookies.
          <br />
          You can modify or withdraw your consent to cookies at any time by changing your cookie settings below. Please
          note that your modification or withdrawal of consent will not delete cookies already placed on your PC.
          <br />
          If you wish to remove the cookies that are already on your equipment, and you are using a PC and a newer
          browser, you can press CTRL + SHIFT + DELETE simultaneously. If this shortcut does not work, you can find the
          support pages for the most commonly used browsers as well as a link to delete flash-cookies here:
          <br />
          <Link
            target="_blank"
            href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
          >
            Internet Explorer
          </Link>
          <br />
          <Link target="_blank" href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
            Mozilla Firefox
          </Link>
          <br />
          <Link target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en-GB">
            Google Chrome
          </Link>
          <br />
          <Link target="_blank" href="https://support.apple.com/kb/PH21411?locale=da_DK&viewlocale=en_UK">
            Safari
          </Link>
          <br />
          <Link
            target="_blank"
            href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
          >
            Flash cookies
          </Link>
          <br />
          You can choose to not receive cookies from Google Analytics{' '}
          <Link href="https://tools.google.com/dlpage/gaoptout" target="_blank">
            here
          </Link>
          .
          <br />
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Which cookies does Novozymes use and for which purposes?</Typography>
      </Box>
      <Box m={2}>
        <Box my={4}>
          <Typography variant="h3">Stricly necessary</Typography>
          <Typography variant="body2">
            Strictly necessary cookies help make a website navigable by activating basic functions such as page
            navigation and access to secure website areas. Without these cookies, the website would not be able to work
            properly.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Expiry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>CookieInformationConsent </TableCell>
                <TableCell>starch.novozymes.cloud</TableCell>
                <TableCell>Supports the website`&apos;`s technical functions.</TableCell>
                <TableCell>1 year</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>cognito</TableCell>
                <TableCell>Novozymes Sign Sign On</TableCell>
                <TableCell>Cookie used to store information about the current logged in user</TableCell>
                <TableCell>Session</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>XSRF-TOKEN</TableCell>
                <TableCell>Novozymes Sign Sign On</TableCell>
                <TableCell>Cookie used for sign in process</TableCell>
                <TableCell>Session</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box my={4}>
          <Typography variant="h3">Functional</Typography>
          <Typography variant="body2">
            Functional cookies make it possible to save information that changes the way the website appears or acts.
            For instance your preferred language or region.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Expiry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>None</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box my={4}>
          <Typography variant="h3">Statistical</Typography>
          <Typography variant="body2">
            Statistical cookies help the website owner understand how visitors interact with the website by collecting
            and reporting information.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Expiry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>_ga</TableCell>
                <TableCell>Google Analytics</TableCell>
                <TableCell>
                  Registers a unique ID that is used to generate statistical data on how the visitor uses the website
                </TableCell>
                <TableCell>2 years</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>_gat</TableCell>
                <TableCell>Google Tag Manager</TableCell>
                <TableCell>Used by Google Analytics to throttle request rate</TableCell>
                <TableCell>a minute</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>_gid</TableCell>
                <TableCell>Google Analytics</TableCell>
                <TableCell>
                  a unique ID that is used to generate statistical data on how the visitor uses the website
                </TableCell>
                <TableCell>a day</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AMP_TOKEN</TableCell>
                <TableCell>Google Analytics</TableCell>
                <TableCell>
                  Contains a token that can be used to retrieve a Client ID from AMP Client ID service. Other possible
                  values indicate opt-out, inflight request or an error retrieving a Client ID from AMP Client ID
                  service.
                </TableCell>
                <TableCell>30 seconds to 1 year</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>_hjid</TableCell>
                <TableCell>Hotjar </TableCell>
                <TableCell>
                  Hotjar cookie that is set when the customer first lands on a page with the Hotjar script. It is used
                  to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in
                  subsequent visits to the same site will be attributed to the same user ID. Hotjar can add multiple
                  other cookies. An overview of the cookies used by hotjar can be found here{' '}
                  <Link href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information">
                    Hotjar cookies
                  </Link>
                </TableCell>
                <TableCell>1 year</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box my={4}>
          <Typography variant="h3">Marketing</Typography>
          <Typography variant="body2">
            Marketing cookies are used to track visitors across websites. The intention is to display ads that are
            relevant and interesting to the individual user and thus more valuable for publishers and third-party
            advertisers.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Expiry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>None</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>

        <Box my={4}>
          <Typography variant="h3">Unclassified</Typography>
          <Typography variant="body2">
            We are in the process of classifying unclassified cookies together with the providers of the individual
            cookies.
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Expiry</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>None</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Box>
      <Box m={2}>
        <Typography variant="h2">Contact</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          If you have questions, comments or complaints about Novozymes’ use of cookies or the information provided in
          this regard, please{' '}
          <Link href="https://www.novozymes.com/en/about-us/contact-us" target="_blank">
            contact Novozymes
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  </>
);

export default Cookies;
